import React from 'react';
import './gardenofpeace.css';

const PopUp = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close" onClick={onClose}>&times;</button>
        <h5>As-Salamu Alaikum,</h5>
        <p>
          The Garden of Peace project, spearheaded by the Bay Area Muslim community, has secured 160 acres of land in Santa Nella, CA, for a community cemetery. We are working diligently to establish the property for burials by January 2025, Inshallah. However, to make this a reality, we require an additional $300K in funding. These funds are vital for essential improvements such as road pavement, land development, gate and fence installation, and other necessary developments. Your continued support is invaluable.
        </p>
        <p>
          <b>Alhamdulillah, we have reopened the <a href="http://www.gardenofpeaceus.org/burialplotpledgeform">Burial Plot Pledge Form</a> to secure additional funding.</b> This form will remain open until the end of October, and we encourage you to contribute and secure your spot in this community cemetery project. Your generous donations will help us meet our financial targets and bring us closer to making this cemetery a reality, Inshallah.
        </p>
        <p>
          <b>Contributions can also be made via check to the PO Box address at 3216, Santa Clara, CA 95055, or electronically using Zelle at gardenofpeace.usa@gmail.com.</b> Your generous donations will not only help us meet our financial targets but also play a significant role in the success of this noble community initiative.
        </p>
        <p>
          <b>Additionally, we are seeking dedicated and compassionate <a href="http://www.gardenofpeaceus.org/volunteering">volunteers</a> to join us in supporting bereaved family members during the funeral and burial process.</b> Guidance and training will be provided to all interested volunteers.
        </p>
        <p>
          For more information, please contact us at:
          <br />
          Phone Number: (669) 214-6771
          <br />
          Email: gardenofpeace.usa@gmail.com
          <br />
          Website: <a href="http://www.gardenofpeaceus.org">www.gardenofpeaceus.org</a>
        </p>
        <p>
          Jazakallahu Khairan,
          <b> Garden of Peace Team</b>
        </p>
      </div>
    </div>
  );
};

export default PopUp;

        {/* 
        <h5>Please Consider Supporting Garden of Peace</h5>
        <p>
        Alhamdulillah! We are immensely grateful to Allah (swt) for bestowing His blessings upon us. Our heartfelt thanks extend to the Bay Area Muslim community for their unwavering support—be it through dua, valuable time, guidance, financial contributions, and more. Together, we've achieved two significant milestones:
        </p>
        <ol>
          <li>Acquired 160 acres of land at 17205 S Jasper Sears Road, Santa Nella, CA 93635.</li>
          <li>Obtained permit from Merced County to use this land as a community cemetery.</li>
        </ol>
        <p>May Allah (swt) accept our collective efforts and continue to bless this endeavor for the benefit of our community. <b>However, in order to operate as a cemetery, we still require an additional $200K for the following:</b></p>
        <ol>
          <li>Improve/pavement of access road</li>
          <li>Improve/develop the land for burial</li>
          <li>Install gate/fence around property</li>
          <li>Landscape per county requirement</li>
          <li>Purchase proper equipment/digger</li>
        </ol>
        <p>
        Your continued support through generous donations propels us forward on this noble journey. Our target is to prepare the property for burial by <b>mid-year 2024, insha'Allah.</b>
        Your timely contribution is crucial in establishing the essential burial facilities and ensuring the success of this community initiative.
        To make your donation, kindly send your <b>check payable to ‘Garden of Peace’</b> to the following address:
        </p>
        <p> 
            <b>PO Box:
        3216, Santa Clara, CA 95055</b>  
        </p>
        <p>
        For electronic payments, you can use <b>Zelle</b> using the email address:
        <u><b> gardenofpeace.usa@gmail.com</b></u>
        </p>
        <p>
        Your contribution (tax deductible) goes beyond financial assistance; it is an investment in the well-being of our Muslim community. Your support not only helps us reach our financial target but also plays a significant role in ensuring the success of this noble endeavor.
        </p>
        <p>
        Jazakallahu khairan,
         <b> Garden of Peace Team</b>
        </p>
      */}

